module.exports = {
  siteMetadata: {
    title: `Dose`,
    description: `On Demand and Scheduled Personal Training Sessions`,
    author: `@papigers`,
    api: "https://dose-backend.herokuapp.com/graphql/",
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: [`montserrat:400,500,600,`, "muli:400"],
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#5A4FED`,
        theme_color: `#5A4FED`,
        display: `minimal-ui`,
        icon: `src/images/dose.png`, // This path is relative to the root of the site.
      },
    },
    "gatsby-plugin-theme-ui",
    {
      resolve: "gatsby-plugin-react-svg",
      // options: {
      //   rule: {
      //     include: /.*/,
      //   },
      // },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
