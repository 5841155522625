/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { createClient, Provider, defaultExchanges } from "urql"
import fetch from "isomorphic-fetch"

import { siteMetadata } from "./gatsby-config"

const client = createClient({
  url: siteMetadata.api,
  fetch,
  exchanges: defaultExchanges,
})

// You can delete this file if you're not using it

export const wrapRootElement = ({ element }, options) => {
  return <Provider value={client}>{element}</Provider>
}
