export default {
  breakpoints: ["40em", "52em", "64em"],
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#5A4FED",
    primaryDark: "#4A3FDD",
    secondary: "#30c",
    muted: "#f6f6f9",
    gray: "#F7F7F7",
    darkGray: "#828282",
    border: "#DBDCDD",
    highlight: "rgba(90, 79, 237, 0.25)",
  },
  fonts: {
    body: "'Montserrat', system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
    quote: "'Muli', serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    semiBold: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    avatar: 104,
  },
  radii: {
    default: 10,
    circle: 99999,
  },
  shadows: {
    card: "0 0 4px rgba(0, 0, 0, .125)",
    avatar: "0px 5px 30px rgba(0, 0, 0, 0.2)",
  },
  // rebass variants
  text: {
    default: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [1, 2],
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [5, 5, 7],
    },
    heading2: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [4, 4, 6],
    },
    quote: {
      variant: "text.default",
      fontFamily: "quote",
    },
    display: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7],
    },
    caps: {
      variant: "text.default",
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
  },
  variants: {
    section: {
      paddingY: [5, 3],
      // minHeight: "80vh",
    },
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "circle",
      boxShadow: "avatar",
      overflow: "hidden",
    },
    card: {
      p: 2,
      bg: "background",
      boxShadow: "card",
    },
    link: {
      color: "primary",
    },
    nav: {
      // fontSize: 1,
      fontSize: [1, 2],
      fontWeight: "bold",
      display: "inline-block",
      // p: 2,
      color: "inherit",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "primary",
      },
    },
  },
  forms: {
    input: {
      variant: "text.default",
      bg: "gray",
      borderColor: "border",
      borderRadius: "default",
    },
  },
  buttons: {
    primary: {
      variant: "text.default",
      fontWeight: "semiBold",
      color: "background",
      bg: "primary",
      py: ["12px", 3],
      lineHeight: 1.2,
      borderRadius: "default",
      transition: "color 200ms ease-in-out, background 200ms ease-in-out",
      "&:hover": {
        bg: "primaryDark",
      },
      "&:focus, &:active": {
        boxShadow: theme => `0 0 0 0.2rem ${theme.colors.highlight}`,
      },
    },
    outline: {
      variant: "buttons.primary",
      color: "primary",
      bg: "transparent",
      boxShadow: "inset 0 0 2px",
    },
    secondary: {
      variant: "buttons.primary",
      color: "background",
      bg: "secondary",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      position: "relative",
      "*:focus, *:hover, *:active": {
        outline: "none",
      },

      ".carousel .slide": {
        backgroundColor: "unset",
      },

      ".Popover-body": {
        backgroundColor: "text",
        color: "background",
        paddingX: 3,
        paddingY: 2,
        borderRadius: 10,
        fontSize: 1,
      },
    },
  },
}
